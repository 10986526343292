import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { Subscription } from 'rxjs';
import firebase from 'firebase/app';

import { useAutosaveLocation } from '../hooks/useAutosaveLocation';
import FirebaseService from '../services/firebase';
import { NamespaceType, RequestStatus } from '../types';
import { AutosaveContext } from './AutosaveContext';

export interface AutosaveRequestContextProps {
  firebaseConnection: FirebaseService | null;
  namespace: NamespaceType;
  timestamp: number;
  debounceWait: number;
  isConnected: boolean;
}

export const AutosaveRequestContext = createContext(
  {} as AutosaveRequestContextProps
);

export interface AutosaveRequestProviderProps {
  namespace: NamespaceType;
  ignoredRoutes?: Array<string | RegExp>;
  debounceWait?: number;
  children: React.ReactNode;
}

export const AutosaveRequestProvider: React.FC<AutosaveRequestProviderProps> =
  ({
    namespace,
    ignoredRoutes = [],
    debounceWait = 500,
    children,
  }: AutosaveRequestProviderProps) => {
    const { firebaseConnection } = useContext(AutosaveContext);

    const [isConnected, setIsConnected] = useState<boolean>(false);
    const [timestamp, setTimestamp] = useState(0);
    const isNewRequest = useRef(false);
    const isRequestActive = useRef(false);
    const needsToCheckTimestamp = useRef(false);
    const location = useLocation();

    useAutosaveLocation(
      firebaseConnection,
      namespace,
      timestamp,
      ignoredRoutes
    );

    const setDraftTimestamp = () => {
      const queryParams = new URLSearchParams(location.search);
      const storedDraftId = sessionStorage.getItem('AUTOSAVE_DRAFT_ID');
      let timestamp = 0;

      if (queryParams.has('draftId')) {
        timestamp = parseInt(queryParams.get('draftId')!, 10);
        needsToCheckTimestamp.current = true;
      } else if (storedDraftId) {
        timestamp = parseInt(storedDraftId!, 10);
        needsToCheckTimestamp.current = true;
      } else {
        timestamp = new Date().getTime();
        isNewRequest.current = true;
      }
      setTimestamp(timestamp);
    };

    const setRequestAsActive = async () => {
      if (firebaseConnection && timestamp) {
        if (needsToCheckTimestamp.current) {
          const isTimestampValid =
            await firebaseConnection?.checkIfTimestampExists(
              namespace,
              timestamp
            );

          if (!isTimestampValid) {
            if (sessionStorage.getItem('AUTOSAVE_DRAFT_ID')) {
              sessionStorage.removeItem('AUTOSAVE_DRAFT_ID');
            }
            return;
          } else {
            sessionStorage.setItem('AUTOSAVE_DRAFT_ID', String(timestamp));
          }
        }

        if (isNewRequest.current) {
          const isPathnameIgnored = (ignoredRoutes ?? []).some(path => {
            if (typeof path === 'string') return path === location.pathname;
            else return path.test(location.pathname);
          });
          if (!isPathnameIgnored) {
            firebaseConnection
              .setRequestStatus(namespace, timestamp, RequestStatus.Active)
              .subscribe(() => {
                isRequestActive.current = true;
              })
              .unsubscribe();
          }
        } else {
          firebaseConnection
            .setRequestStatus(namespace, timestamp, RequestStatus.Active)
            .subscribe(() => {
              isRequestActive.current = true;
            })
            .unsubscribe();
        }
      }
    };

    useEffect(() => {
      setDraftTimestamp();

      return () => sessionStorage.removeItem('AUTOSAVE_DRAFT_ID');
    }, []);

    useEffect(() => {
      if (!isRequestActive.current) {
        setRequestAsActive();
      }
    }, [firebaseConnection, timestamp, location]);

    useEffect(() => {
      let subscription: Subscription;
      if (firebaseConnection) {
        subscription = firebaseConnection
          .handleConnectionChanges()
          .subscribe((snapshot: firebase.database.DataSnapshot) => {
            setIsConnected(snapshot.val() === true);
          });
      }

      return () => subscription?.unsubscribe();
    }, [firebaseConnection]);

    return (
      <AutosaveRequestContext.Provider
        value={{
          firebaseConnection,
          namespace,
          timestamp,
          debounceWait,
          isConnected,
        }}>
        {children}
      </AutosaveRequestContext.Provider>
    );
  };
