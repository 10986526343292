import React, { createContext, useEffect, useState } from 'react';

import FirebaseService from '../services/firebase';
import { handleAutosaveAuth } from '../services/auth';
import { ConnectionConfig } from '../types';

export interface AutosaveContextProps {
  firebaseConnection: FirebaseService | null;
}

export const AutosaveContext = createContext({} as AutosaveContextProps);

export interface AutosaveProviderProps {
  connectionConfig: ConnectionConfig;
  children: React.ReactNode;
}

export const AutosaveProvider: React.FC<AutosaveProviderProps> = ({
  connectionConfig,
  children,
}: AutosaveProviderProps) => {
  const [firebaseConnection, setFirebaseConnection] =
    useState<FirebaseService | null>(null);

  useEffect(() => {
    const getInitializedFirebaseConnection = async () => {
      const { authServiceUrl, avenue8Token } = connectionConfig;
      const firebaseConfig = await handleAutosaveAuth(
        authServiceUrl,
        avenue8Token
      );

      const firebaseConnection = new FirebaseService(firebaseConfig);
      await firebaseConnection.initialize();
      return firebaseConnection;
    };

    getInitializedFirebaseConnection().then(connection =>
      setFirebaseConnection(connection)
    );
  }, []);

  return (
    <AutosaveContext.Provider value={{ firebaseConnection }}>
      {children}
    </AutosaveContext.Provider>
  );
};
