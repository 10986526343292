import { useState, useEffect, useContext } from 'react';
import { AutosaveContext } from '../context/AutosaveContext';

export const useRequestsCount = () => {
  const { firebaseConnection } = useContext(AutosaveContext);
  const [requestsCount, setRequestsCount] = useState(0);

  useEffect(() => {
    if (firebaseConnection) {
      firebaseConnection
        .getRequestCount()
        .subscribe(requestsCount => setRequestsCount(requestsCount));
    }
  }, [firebaseConnection]);

  return requestsCount;
};
