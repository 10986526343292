export enum NamespaceType {
  Marketing = 'Marketing',
  Signage = 'Signage',
  Comps = 'Comps',
}

export enum SubFlowType {
  // Marketing
  SocialMedia = 'Social Media',
  DisplayAds = 'Display Ads',
  EmailBlast = 'Email Blast',
  Postcard = 'Postcard',
  DoubleSidedFlyer = 'Double-Sided Flyer',
  BiFoldBrochure = 'BiFold Brochure',
  // Signage
  NewSign = 'New Sign',
  ExistingSign = 'Existing Sign',
}

export enum ErrorType {
  InvalidCustomToken = 'The token you provided is not valid.',
}

export enum RequestStatus {
  Active = 'Active',
  ForLater = 'ForLater',
}

export interface FirebaseConfig {
  apiKey: string;
  appId: string;
  projectId: string;
  databaseURL: string;
  firebaseToken: string;
  userHash?: string;
}

export interface ConnectionConfig {
  authServiceUrl: string;
  avenue8Token: string;
}
