import axios from 'axios';

const authServiceEndpoint = 'autosave/credentials';

const requestAuthConfigs = async (
  authServiceUrl: string,
  a8AccessToken: string
) => {
  const config = {
    headers: { Authorization: `Bearer ${a8AccessToken || 'SAMPLE_TOKEN'}` },
  };
  const response = await axios
    .get(`${authServiceUrl}/${authServiceEndpoint}`, config)
    .then(response => {
      return response.data;
    })
    .catch(() => {
      throw new Error('AUTH FAILED');
    });
  return response;
};

const configsAreValid = (configs: any) => {
  return (
    configs !== undefined &&
    configs.appId !== undefined &&
    configs.firebaseToken !== undefined
  );
};

export const handleAutosaveAuth = async (
  authServiceUrl: string,
  userToken: string
) => {
  try {
    const configs = await requestAuthConfigs(authServiceUrl, userToken);
    if (configsAreValid(configs)) {
      return configs;
    } else {
      throw new Error('AUTH FAILED');
    }
  } catch (error) {
    throw new Error('AUTH FAILED');
  }
};
