import firebase from 'firebase/app';
import 'firebase/database';
import 'firebase/auth';
import { Observable } from 'rxjs';

import {
  FirebaseConfig,
  ErrorType,
  NamespaceType,
  RequestStatus,
  SubFlowType,
} from '../types';

class FirebaseService {
  private app!: firebase.app.App;
  private config: FirebaseConfig;
  private userUid: string = '';

  constructor(config: FirebaseConfig) {
    this.config = config;
  }

  async initialize() {
    if (!firebase.apps.length) {
      this.app = firebase.initializeApp(this.config);
    } else {
      this.app = firebase.app();
    }

    return firebase
      .auth()
      .signInWithCustomToken(this.config.firebaseToken)
      .then(credentials => (this.userUid = credentials.user!.uid))
      .catch(error => {
        if (error.code === 'auth/invalid-custom-token') {
          throw new Error(ErrorType.InvalidCustomToken);
        } else {
          throw new Error(error);
        }
      });
  }

  get database(): firebase.database.Database {
    return this.app.database();
  }

  handleConnectionChanges(): Observable<firebase.database.DataSnapshot> {
    return new Observable(subscriber => {
      this.database
        .ref('.info/connected')
        .on('value', snapshot => subscriber.next(snapshot));
    });
  }

  checkIfTimestampExists(
    namespace: NamespaceType,
    timestamp: number
  ): Promise<boolean> {
    return this.database
      .ref(`${this.userUid}/${namespace}/${timestamp}`)
      .get()
      .then(snapshot => snapshot.exists())
      .catch(() => false);
  }

  getRequestInProgressData(
    namespace: NamespaceType,
    timestamp: number,
    key: string
  ): Observable<firebase.database.DataSnapshot> {
    return new Observable(subscriber => {
      const requestRef = this.database.ref(
        `${this.userUid}/${namespace}/${timestamp}`
      );

      requestRef
        .get()
        .then(snapshot => {
          if (snapshot.exists()) {
            const keyRef = requestRef.child(key);
            keyRef.on('value', snapshot => subscriber.next(snapshot));
          }
        })
        .catch(error => subscriber.error(error));
    });
  }

  patchRequestInProgress(
    namespace: NamespaceType,
    timestamp: number,
    key: string,
    data: any
  ): Observable<Error | null> {
    return new Observable(subscriber => {
      const requestRef = this.database.ref(
        `${this.userUid}/${namespace}/${timestamp}`
      );

      requestRef
        .get()
        .then(snapshot => {
          if (snapshot.exists()) {
            const keyRef = requestRef.child(key);
            keyRef.set(data, complete => subscriber.next(complete));
          }
        })
        .catch(error => subscriber.error(error));
    });
  }

  deleteRequest(
    namespace: NamespaceType,
    timestamp: number
  ): Observable<Error | null> {
    return new Observable(subscriber => {
      this.database
        .ref(`${this.userUid}/${namespace}/${timestamp}`)
        .remove()
        .then(() => subscriber.next())
        .catch(error => subscriber.error(error));
    });
  }

  setRequestStatus(
    namespace: NamespaceType,
    timestamp: number,
    status: RequestStatus
  ): Observable<Error | null> {
    return new Observable(subscriber => {
      const ref = this.database
        .ref(`${this.userUid}/${namespace}/${timestamp}`)
        .child('AUTOSAVE_STATUS');

      ref.get().then(storedStatus => {
        if (storedStatus.val() !== status) {
          ref.set(status, complete => subscriber.next(complete));
        } else {
          subscriber.next();
        }
      });
    });
  }

  getLatestRequest(): Observable<any> {
    return new Observable(subscriber => {
      const ref = this.database.ref(this.userUid);

      ref
        .get()
        .then(snapshot => {
          if (snapshot.exists()) {
            const requests = snapshot.val();

            let latestRequestTimestamp: number = 0;
            let latestRequestNamespace: NamespaceType | null = null;
            let latestRequestSubflow: SubFlowType | null = null;
            let latestRequestListingAddress: string = '';

            for (const request of Object.entries(requests)) {
              const [namespace, namespaceData]: [string, any] = request;

              const requestData: [string, any] | undefined = Object.entries(
                namespaceData
              ).find(
                ([, timestampData]: [string, any]) =>
                  timestampData?.AUTOSAVE_STATUS === RequestStatus.Active
              );

              if (requestData) {
                const [timestamp, data] = requestData;
                latestRequestTimestamp = parseInt(timestamp, 10);
                latestRequestNamespace = namespace as NamespaceType;
                latestRequestListingAddress =
                  data.AUTOSAVE_LISTING_ADDRESS ||
                  data.AUTOSAVE_PRE_LISTING_ADDRESS ||
                  '';

                if (data.AUTOSAVE_SUBFLOW) {
                  latestRequestSubflow = data.AUTOSAVE_SUBFLOW;
                }
                break;
              }
            }

            if (latestRequestTimestamp && latestRequestNamespace) {
              subscriber.next({
                timestamp: latestRequestTimestamp,
                namespace: latestRequestNamespace,
                subflow: latestRequestSubflow,
                listingAddress: latestRequestListingAddress,
              });
            } else {
              subscriber.next(null);
            }
          } else {
            subscriber.next(null);
          }
        })
        .catch(error => subscriber.error(error));
    });
  }

  getRequestCount(): Observable<number> {
    return new Observable(subscriber => {
      const ref = this.database.ref(this.userUid);

      ref.on('value', (snapshot: firebase.database.DataSnapshot) => {
        if (snapshot.exists()) {
          const requests = snapshot.val();

          let requestsCount = 0;
          for (const request of Object.entries(requests)) {
            const [, namespaceData]: [string, any] = request;

            requestsCount += Object.values(namespaceData).filter(
              (data: any) => data.AUTOSAVE_STATUS === RequestStatus.ForLater
            ).length;
          }

          subscriber.next(requestsCount);
        } else {
          subscriber.next(0);
        }
      });
    });
  }

  getAllRequests(status?: RequestStatus): Observable<{}> {
    return new Observable(subscriber => {
      const ref = this.database.ref(this.userUid);

      ref.on('value', (snapshot: firebase.database.DataSnapshot) => {
        if (snapshot.exists()) {
          const requests = snapshot.val();

          if (status) {
            const allRequestsBasedOnStatus = {};
            for (const request of Object.entries(requests)) {
              const [namespace, namespaceData]: [string, any] = request;

              const forLaterRequestsArray: Array<[string, any]> =
                Object.entries(namespaceData).filter(
                  ([, timestampData]: [string, any]) =>
                    timestampData?.AUTOSAVE_STATUS === status
                );

              if (forLaterRequestsArray.length > 0) {
                const forLaterRequests = Object.fromEntries(
                  forLaterRequestsArray
                );
                allRequestsBasedOnStatus[namespace] = forLaterRequests;
              }
            }

            subscriber.next(allRequestsBasedOnStatus);
          } else {
            subscriber.next(requests || {});
          }
        } else {
          subscriber.next({});
        }
      });
    });
  }
}

export default FirebaseService;
