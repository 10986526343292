import React, { useContext, useEffect, useState } from 'react';
import { DiveBackInModal } from 'av8-ui';

import { NamespaceType, RequestStatus, SubFlowType } from '../types';
import { AutosaveContext } from '../context/AutosaveContext';

interface RequestData {
  timestamp: number;
  namespace: NamespaceType;
  subflow: SubFlowType | null;
  listingAddress: string | null;
}

export const AutosaveModal = ({
  redirectTo,
  getNamespaceImage,
}: {
  redirectTo: (namespace: NamespaceType, timestamp: number) => void;
  getNamespaceImage: (
    namespace: NamespaceType,
    subflow: SubFlowType | null
  ) => string;
}) => {
  const { firebaseConnection } = useContext(AutosaveContext);

  const [latestRequest, setLatestRequest] = useState<RequestData | null>(null);

  useEffect(() => {
    if (firebaseConnection) {
      firebaseConnection
        .getLatestRequest()
        .subscribe((requestData: RequestData | null) => {
          if (requestData) {
            const {
              timestamp,
              namespace,
              listingAddress,
              subflow = null,
            } = requestData;
            setLatestRequest({
              namespace,
              timestamp,
              subflow,
              listingAddress,
            });
          }
        });
    }
  }, [firebaseConnection]);

  const continueRequest = () => {
    redirectTo(latestRequest?.namespace!, latestRequest?.timestamp!);
  };

  const continueRequestLater = () => {
    firebaseConnection
      ?.setRequestStatus(
        latestRequest!.namespace,
        latestRequest!.timestamp,
        RequestStatus.ForLater
      )
      .subscribe()
      .unsubscribe();
  };

  const getRequestSubtitle = () => {
    if (
      latestRequest?.listingAddress &&
      (NamespaceType.Marketing || NamespaceType.Signage || NamespaceType.Comps)
    ) {
      return latestRequest?.listingAddress || '';
    } else if (latestRequest?.subflow) {
      return latestRequest?.subflow;
    }
    return '';
  };

  return latestRequest ? (
    <DiveBackInModal
      requestTitle={`${latestRequest?.namespace} Request`}
      requestSubtitle={getRequestSubtitle()}
      imgSrc={getNamespaceImage(
        latestRequest?.namespace,
        latestRequest?.subflow
      )}
      timestamp={latestRequest.timestamp}
      continueRequest={continueRequest}
      continueRequestLater={continueRequestLater}
    />
  ) : null;
};
